/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import BookMeetingButton from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandActions/BookMeetingButton';
import BrandOnlineShopButton from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandActions/BrandOnlineShopButton';

export default function BrandActions({ brand, ...rest }) {
  if (!hasContent(brand)) return null;
  return (
    <Box {...rest}>
      <BrandOnlineShopButton brand={brand} sx={{ marginRight: 2 }} />
    </Box>
  );
}

BrandActions.hasContent = hasContent;

function hasContent(brand) {
  if (BookMeetingButton.hasContent(brand)) return true;
  if (BrandOnlineShopButton.hasContent(brand)) return true;
  return false;
}

export const fragment = graphql`
  fragment bb_BrandActions on Bottlebooks_AbstractBrand {
    ...bb_BookMeetingButton
    ...bb_BrandOnlineShopButton
  }
`;

BrandActions.fragment = gql`
  fragment BrandActions on AbstractBrand {
    ...BookMeetingButton
    ...BrandOnlineShopButton
  }
  ${BookMeetingButton.fragment}
  ${BrandOnlineShopButton.fragment}
`;
